var $ = require("jquery");
import Parallax from 'jquery-match-height';

// console.log("match heigh");
// $(function() {
// 	// $('.blog-teaser-col').matchHeight();
// 	$('.item').matchHeight({ property: 'min-height' });
// });


// $('.blog-teaser .row ').each(function() {
//     $(this).children('.blog-teaser-col').matchHeight({
//         byRow: byRow
//     });
// });
// require("~bootstrap-4-grid/scss/grid.scss");
// require("@bootstrap-4-grid/scss/grid.scss");
require("./style.scss");



var $ = require("jquery");

var $window = $(window);
	var $document = $(document);
	var $body = $('body');

		var $navigationToggle = $('#navigation-toggle');
		var $navigation = $('nav');
			var $navigationInner = $('#navigation');


		var $searchToggle = $('#search');
		var $searchClose = $('.search-close');


var $navigationWidth = 0;
var $navigationInnerWidth = 0;

var $scrollTop = $document.scrollTop();

var $isMobileHeader = false;
var $scrollHeader = false;

var $navigationHTML = $('.navigation-inner').html();

// var $mobileMenu = $('<div />').attr('id','mobile-menu').html($navigationHTML);
var $mobileMenu = $('<div />').attr('id','mobile-menu').html('<div class="container"><div class="row"><div class="col">'+$navigationHTML+'</div></div></div>');

var $navigationWidth = $navigation.width();
var $navigationInnerWidth = $navigationInner.width();


function resizeHeader() {
	$navigationWidth = $navigation.width();
	$navigationInnerWidth = $navigationInner.width();

	

	// if($navigationWidth < $navigationInnerWidth) {
	if($window.width() < 767 ) {
		mobileMenu(true);
	} else {
		mobileMenu(false);
	}
}


function mobileMenu(isMobile) {
	if(isMobile != $isMobileHeader) {
		$isMobileHeader = isMobile;
	
		if($isMobileHeader) {
			$body.addClass('mobile-header');
			$body.removeClass('menu-is-open');
			// $navigationToggle.prop( "checked", false );
			$mobileMenu.appendTo($navigation.parent());

		}
		else {
			$body.removeClass('mobile-header');
			$body.removeClass('menu-is-open');
			// $navigationToggle.prop( "checked", false );
			$mobileMenu.remove();
		}
	}
}


function scrollHeader(scrollHeader) {
	if($scrollHeader != scrollHeader) {
		if(scrollHeader) {
			$body.addClass('scroll-header');
		} else {
			$body.removeClass('scroll-header');
		}

		$scrollHeader = scrollHeader;
	}
}


resizeHeader();
scrollHeader();

$window.resize(function(){
	resizeHeader();
}).scroll(function(e){
	var currentScrollPos = $window.scrollTop();
	if(currentScrollPos > 5) scrollHeader(true);
	else scrollHeader(false);
});



$navigationToggle.on("click",function(){
	$body.toggleClass("menu-is-open");
});

// $body.toggleClass("search-is-open");
$searchToggle.on("click",function(){
	$body.toggleClass("search-is-open");
});

$searchClose.on("click",function(){
	$body.removeClass("search-is-open");
});


$document.on('keyup',function(evt) {
    if (evt.keyCode == 27) {
       $body.removeClass("search-is-open");
    }
});





// $body.addClass('mobile-header');
// $body.addClass('menu-is-open');
/***
* https://github.com/verlok/lazyload
* https://tgomilar.github.io/paroller.js
* https://github.com/angusfretwell/parallax
**/

// var $ = require("jquery");
// var paroller = require('paroller.js'):
// $(".parallax img").paroller({ factor: 0.2, type: 'foreground'});

import LazyLoad from "vanilla-lazyload";
import Parallax from 'parallax-scroll';

var responsiveImages = new LazyLoad({
    elements_selector: ".lazy"
});

// responsiveImages.update();



var parallax = new Parallax('.js-parallax', {
  speed: 0.2, //0.4, // 0.2
  touch: false, // Presents a mobile/tablet friendy version
});

parallax.animate();





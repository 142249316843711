// Base
  // require('./bootstrap/bootstrap')


 // Utils
 	require('./utils/responsive_image')
 	require('./utils/match_height')
 	// require('./utils/hash')


 // Skeleton
 	// require('./skeleton/header')
 	require('../components/header');
 	require('../components/cookie');

// Components
  // require('./components/modal')
  // require('./components/team')
  // require('./components/glide')
  // require('./components/plyr')

  console.log("app");